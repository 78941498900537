<template>
  <b-form @submit.prevent="saveSettings">
    <b-card
      title="Messaging"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Channel"
            label-for="mc-channel"
          >
            <b-form-select
              id="mc-channel"
              :options="channels"
              placeholder="Select Channel"
              v-model="details.channel"
            >
              <option value="" disabled>--- Select Channel ---</option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Subject"
            label-for="mc-subject"
          >
            <b-form-input
              id="mc-subject"
              v-model="details.subject"
              placeholder="Subject"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Message"
            label-for="mc-message" 
          >
            <ckeditor :editor="editor" v-model="details.message" :config="editorConfig"></ckeditor>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Send
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BSpinner, BFormTextarea, BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';

export default {
  components: {
    ClassicEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    BCard,
    BSpinner,
    BFormSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      settings: {},
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        modules: {
          toolbar: '#toolbar',
        },
      }, 
     details: {
       subject: '',
       message: '',
       channel: '',
     },
      loading: false,
      channels: [
        { value: 'all', text: 'All' },
        { value: 'push_notification', text: 'Push Notification' },
        { value: 'email', text: 'Email' },
      ],
    }
  },
  methods: {
    saveSettings() {
      this.loading = true
      this.$http.post(`${this.$url}/messaging/send-email`, this.details)
        .then(response => {
          if (response.data.status) {
            // window.location.reload()
            Swal.fire({
              icon: 'success',
              text: 'Email successfully sent',
            })
          }
        })
        .catch((err) => {
          if(err.response.data) {
            let message
            if(err.response.data.errors) {
              let errors = err.response.data.errors
              let errorList = Object.values(errors)
              errorList.map(msg => {
                message = msg
              })
            }
            Swal.fire({
              icon: 'error',
              text: message || err.response.data.message
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
